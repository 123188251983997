var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      class: { "is-active": _vm.showSharedViews },
      staticStyle: { "z-index": "1006 !important" },
    },
    [
      _c("div", {
        staticClass: "modal-background",
        on: { click: _vm.toggleClose },
      }),
      _c("div", { staticClass: "modal-card" }, [
        _c("section", { staticClass: "modal-card-body notification" }, [
          _c(
            "button",
            {
              staticClass: "delete",
              attrs: { "aria-label": "close" },
              on: { click: _vm.toggleClose },
            },
            [_vm._v(" Close ")]
          ),
          _c("h3", { staticClass: "title is-6 has-text-success" }, [
            _vm._v("Public Views"),
          ]),
          _c("table", { staticClass: "has-text-left table is-fullwidth" }, [
            _vm._m(0),
            _vm.shareViewsList.length > 0
              ? _c(
                  "tbody",
                  _vm._l(_vm.shareViewsList, function (view) {
                    return _c("tr", { key: view.id }, [
                      _c("th", { staticClass: "is-size-7" }, [
                        _vm._v(_vm._s(view.name)),
                      ]),
                      _c("th", { staticClass: "is-size-7" }, [
                        _vm._v(_vm._s(view.region)),
                      ]),
                      _c("th", { staticClass: "is-size-7" }, [
                        _vm._v(_vm._s(view.environment)),
                      ]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "button is-small is-rounded",
                            on: {
                              click: function ($event) {
                                return _vm.copyDetailsToCilpBoard(view.urlId)
                              },
                            },
                          },
                          [_vm._v(" Copy URL ")]
                        ),
                      ]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "button is-small is-rounded",
                            on: {
                              click: function ($event) {
                                return _vm.openDeleteModal(view)
                              },
                            },
                          },
                          [_vm._v(" Remove ")]
                        ),
                      ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _c("div", [
              _vm.shareViewsList.length == 0
                ? _c("p", [_vm._v("No View found")])
                : _vm._e(),
            ]),
            _c("tfoot"),
          ]),
          _c("div", { staticClass: "columns is-gapless" }, [
            _c("div", { staticClass: "column mx-1" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newView.name,
                    expression: "newView.name",
                  },
                ],
                staticClass: "input is-small",
                attrs: { type: "text", placeholder: "Name" },
                domProps: { value: _vm.newView.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.newView, "name", $event.target.value)
                  },
                },
              }),
              _c("p", [_vm._v("Select Region")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.newView.region,
                      expression: "newView.region",
                    },
                  ],
                  staticClass: "select is-small is-fullwidth my-1",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.newView,
                        "region",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.regions, function (optionView) {
                  return _c(
                    "option",
                    {
                      key: optionView.name,
                      domProps: {
                        selected: optionView.name == _vm.$store.state.region,
                        value: optionView.name,
                      },
                    },
                    [_vm._v(" " + _vm._s(optionView.name) + " ")]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("footer", { staticClass: "card-footer" }, [
            _c(
              "button",
              {
                staticClass: "button is-success is-fullwidth is-small",
                class: _vm.isAdding ? "is-loading" : "",
                on: { click: _vm.addSharedView },
              },
              [_vm._v(" Add ")]
            ),
          ]),
        ]),
      ]),
      _vm.showDeleteModal
        ? _c("SharedViewDeleteModal", {
            attrs: {
              showDeleteModal: _vm.showDeleteModal,
              selectedView: _vm.selectedView,
            },
            on: { closeModal: _vm.closeDeleteModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _c("th", [_vm._v("Region")]),
        _c("th", [_vm._v("Environment")]),
        _c("th"),
        _c("th"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }