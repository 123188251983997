<template>
  <div
    class="modal"
    style="z-index: 1006 !important"
    v-bind:class="{ 'is-active': showSharedViews }"
  >
    <div class="modal-background" @click="toggleClose"></div>
    <div class="modal-card">
      <section class="modal-card-body notification">
        <button class="delete" aria-label="close" @click="toggleClose">
          Close
        </button>
        <h3 class="title is-6 has-text-success">Public Views</h3>
        <table class="has-text-left table is-fullwidth">
          <thead>
            <tr>
              <th>Name</th>
              <th>Region</th>
              <th>Environment</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="shareViewsList.length > 0">
            <tr v-for="view in shareViewsList" :key="view.id">
              <th class="is-size-7">{{ view.name }}</th>
              <th class="is-size-7">{{ view.region }}</th>
              <th class="is-size-7">{{ view.environment }}</th>
              <td>
                <button
                  class="button is-small is-rounded"
                  @click="copyDetailsToCilpBoard(view.urlId)"
                >
                  Copy URL
                </button>
              </td>
              <td>
                <button
                  class="button is-small is-rounded"
                  @click="openDeleteModal(view)"
                >
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
          <div>
            <p v-if="shareViewsList.length == 0">No View found</p>
          </div>
          <tfoot></tfoot>
        </table>
        <div class="columns is-gapless">
          <div class="column mx-1">
            <input class="input is-small" type="text" v-model="newView.name" placeholder="Name" />
            <p>Select Region</p>
            <select
              class="select is-small is-fullwidth my-1"
              v-model="newView.region"
            >
              <option
                v-for="optionView in regions"
                :selected="optionView.name == $store.state.region"
                :key="optionView.name"
                :value="optionView.name"
              >
                {{ optionView.name }}
              </option>
            </select>
          </div>
        </div>
        <footer class="card-footer">
          <button
            class="button is-success is-fullwidth is-small"
            v-bind:class="isAdding ? 'is-loading' : ''"
            @click="addSharedView"
          >
            Add
          </button>
        </footer>
      </section>
    </div>
    <SharedViewDeleteModal
      v-if="showDeleteModal"
      :showDeleteModal="showDeleteModal"
      :selectedView="selectedView"
      @closeModal="closeDeleteModal"
    />
  </div>
</template>
<script>
import { addSharedView } from "@/store/fireStore";

export default {
  components: {
    SharedViewDeleteModal: () =>
      import("@/components/SharedViews/SharedViewDeleteModal"),
  },
  data() {
    return {
      isAdding: false,
      showDeleteModal: false,
      selectedView: null,
      newView: {
        name: "",
        url: "",
        region: "",
        environment: this.$store.state.environment,
      },
    };
  },
  props: {
    showSharedViews: {
      type: Boolean,
    },
    regions: {
      type: Array,
    },
  },
  methods: {
    toggleClose() {
      this.$emit("closeModal");
    },
    async addSharedView() {
      this.isAdding = true;
      if (this.newView.name === "") {
        this.isAdding = false;
        this.$notify({
          title: "Please enter a name & a region",
          group: "zones",
          type: "error",
        });
        return;
      }
      try {
        console.log(this.newView)
        await addSharedView(this.newView);
        this.isAdding = false;
        this.$notify({
          type: "success",
          group: "zones",
          title: "View Added Successfully",
        });
        this.newView.name = "";
      } catch (err) {
        this.isAdding = false;
        this.$notify({
          type: "error",
          group: "zones",
          title: "Error Adding View",
        });
      }
    },
    openDeleteModal(zone) {
      this.showDeleteModal = true;
      this.selectedView = zone;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.selectedView = null;
    },
    copyTextToClipBoard(text) {
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
    copyDetailsToCilpBoard(url) {
      this.copyTextToClipBoard(
        `https://${process.env.VUE_APP_SHYFTBASE_CUSTOMER_ID}.mapfleet.ca/views/${this.newView.environment}?id=${url}`
        // `http://localhost:8080/views?id=${url}`
      );
      this.$notify({
        type: "success",
        group: "zones",
        title: "Copied to clipboard",
      });
    },
  },
  computed: {
    shareViewsList() {
      return this.$store.state.sharedViews;
    },
  },
};
</script>
